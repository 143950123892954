<template>
  <div class="wrapper whole-w-h">
      <div class="content">
          <Form validate-trigger="onSubmit" :show-error-message="true" ref='forms'>
              <DropdownMenu :close-on-click-outside="false" class="menu-drop">
                <DropdownItem :options="commSelList" ref="titleSel" @change="changeTitle"/>
              </DropdownMenu>
              <Field v-model="form.title" name="title" label="小区名称：" placeholder="请选择小区名称" :rules="rules" :required="true" @input="handleTitleInput" @focus="handleTitleFocus" class="title-field"/>
              <Field v-model="form.content" name="content" label="点赞说明：" type="textarea" placeholder="请说出您觉得很赞的事~" :rules="rules" :required="true" style="margin-top: 10px;"/> 
              <Field name="picture" label="">
                  <template #input>
                      <Uploader multiple v-model="uploader" accept="image/*" @oversize="onOversize" :max-size="3*1024*1024" :max-count="5">
                        <div class="upload-area">
                          <Icon name="plus" color="#0B0F15" size="30"/>
                          <span>上传图片</span>
                          <div class="text-span">(最多5张)</div>
                        </div>
                      </Uploader>
                  </template>
              </Field>
              <Field name="anonymous" label="是否匿名：" :rules="rules" :required="true" style="margin-top: 10px;">
                <template #input>
                  <RadioGroup v-model="form.anonymous" direction="horizontal" icon-size="16px" checked-color="#42A3FC" @change="changeAnonymous">
                    <Radio name="1">是</Radio>
                    <Radio name="2">否</Radio>
                  </RadioGroup>
                </template>
              </Field>
              <Field name="needReply" label="需要回复：" :rules="rules" :required="true">
                <template #input>
                  <RadioGroup v-model="form.needReply" direction="horizontal" icon-size="16px" checked-color="#42A3FC">
                    <Radio name="1">需回复</Radio>
                    <Radio name="2">无需回复</Radio>
                  </RadioGroup>
                </template>
              </Field>
              <Field v-model="form.fullname" name="fullname" label="联系人：" placeholder="请输入姓名" :rules="rules2" :required="form.anonymous=='2'" style="margin-top: 10px;"/>
              <Field v-model="form.telephone" name="telephone" label="联系电话：" placeholder="请输入联系电话" :rules="rules3" :required="form.anonymous=='2'" type="number" maxlength="11"/>
          </Form>
      </div>
      <div class="button-box whole-width">
          <Button type="primary" round :color="submitFlag?'#ccc':'#42A3FC'" :disabled="submitFlag" @click="submitFun()" class="whole">提交</Button>
      </div>
  </div>
</template>
<script>
import { Icon, Form, Field, Uploader, Button, Toast, RadioGroup, Radio, DropdownMenu, DropdownItem } from "vant";
import { addThumbsUp, imgUpload } from "@/api/common";
import { compressImgBySize } from '@/utils/compressImg'
import { COMM_LIST } from './data'
import { debounceFn } from '@/utils'
const debounce = debounceFn()

const FORM = {
  "title": "",
  "content": "",
  "anonymous": false,
  "needReply": false,
  "fullname": "",
  "telephone": "",
  "picture": ''
}
export default {
  components: {
      Icon,
      Form,
      Field,
      Uploader,
      Button,
      RadioGroup,
      Radio,
      DropdownMenu,
      DropdownItem
  },
  data() {
      return {
          uploader: [],
          form: Object.assign({}, FORM),
          submitFlag: false,
          uploaderBase64:[],
          rules: [{ required: true, trigger: 'onSubmit' }],
          commList: COMM_LIST.map(item=>({text:item,value:item})),
          commSelList: COMM_LIST.map(item=>({text:item,value:item}))
      }
  },
  computed:{
    rules2(){
      if(this.form.anonymous=='2'){
        return [{ required: true, trigger: 'onSubmit' }]
      }else{
        return [{ required: false, trigger: 'onSubmit' }]
      }
    },
    rules3(){
      if(this.form.anonymous=='2'){
        return [{ required: true, trigger: 'onSubmit', message: '手机号限定11位',validator: (value)=>{
          if(value && value.length==11){
            return true
          }else{
            return false
          }
        } }]
      }else{
        return [{ required: false, trigger: 'onSubmit', message: '手机号限定11位',validator: (value)=>{
          if(value && value.length!==11){
            return false
          }else {
            return true
          }
        } }]
      }
    }
  },
  mounted() {
      document.title = this.$route.meta.title
  },
  methods: {
      changeTitle(e){
        this.form.title = e
      },
      handleTitleInput(e){
        debounce(()=>{
            let val = e.trim()
            if(val){
              let a = this.commList.filter(item => item.text.indexOf(val) != -1)
              this.commSelList = [...a]
            }else{
              this.commSelList = [...this.commList]
            } 
        },100)        
      },
      handleTitleFocus(){
        this.$refs.titleSel.toggle(true)
      },
      changeAnonymous(){
        this.$refs.forms.resetValidation(['fullname', 'telephone'])
      },
      submitFun() {
          if (!this.submitFlag) {
              this.$refs.forms.validate().then(async () => {
                  // if (this.uploader.length == 0) {
                  //     Toast.fail({ message: '请上传图片！', duration: 1500 });
                  //     return
                  // }
                  if(this.uploader.length && this.uploader.length>5){
                    this.uploader.splice(0,5)
                  }
                  try{
                      Toast.loading({
                          message: '提交中...',
                          forbidClick: true,
                          duration: 0
                      })
                      this.submitFlag = true
                      if(this.uploader.length){                      
                        let uuidSet = []
                        this.uploaderBase64 = []
                        for(let j=0;j<this.uploader.length;j++){
                          this.uploaderBase64.push({
                            base64Str: this.uploader[j].content,
                          })
                        }
                        for(let k=0;k<this.uploaderBase64.length;k++){
                            uuidSet.push(imgUpload(this.uploaderBase64[k]))
                        }
                        const res = await Promise.all(uuidSet).catch(error=>{
                          this.submitFlag = false
                          Toast.clear()
                          Toast.fail({ message: error, duration: 1500 })
                        })
                        Toast.clear()
                        let compressShopPics = res.map(d=>d.data.uuid)
                        this.form.picture = compressShopPics.join(',')
                      }
                      this.form.anonymous = this.form.anonymous == '1'? true: false
                      this.form.needReply = this.form.needReply == '1'? true: false
                      this.addFun()
                  } catch (e){}
               }).catch(() => {
                  // Toast.fail({ message: '请将信息填写完整！', duration: 1500 })
              })
          } else {
              Toast.fail({ message: '正在提交，请等待', duration: 1500 });
          }
      },
      async addFun(){
        await addThumbsUp(this.form).then(res => {
            if (res.result == 0) {
                Toast.success({ message: '提交成功！', duration: 1500 })
                setTimeout(() => {
                    this.submitFlag = false
                    this.form = Object.assign({}, FORM)
                    this.uploader = []
                }, 500)
            } else {
                this.submitFlag = false
                Toast.fail({ message: res.msg, duration: 1500 })
            }
        }).catch(response => {
            this.submitFlag = false
            Toast.fail('接口错误')
        })
      },
      // 文件上传大小限制
      onOversize(file){
        Toast.fail('图片大小不能超过3M')
      }
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  background: #F5F8FC;
  .content {
      height: calc(100% - 80px);
      overflow: auto;
      ::v-deep .van-dropdown-menu__bar{
        background-color: transparent;
        box-shadow: none;
      }
      ::v-deep .van-cell {
        border-bottom: 1px solid #F3F3F3;
      }
      ::v-deep .van-field__label {
        margin-right: 0;
        color: #000000;
      }
      ::v-deep .van-radio__label {
        font-size: 14px;
      }
  }

  .upload-area{
    width: 75px;
    height: 75px;
    border-radius: 4px;
    background: #F5F8FC;
    color: #AFAFAF;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .text-span{
      font-size: 10px;
      line-height: 12px;
    }
  }

  .button-box {
      height: 80px;
      background: #FFFFFF;
      .whole{
          width: 90%;
          height: 40px;
          margin: 20px 5% 0;
      }
  }

  .menu-drop{
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
  }

  .title-field{
    z-index: 2;
  }
}
</style>