export const COMM_LIST=[
     '非小区',
     '陈南路418弄',
     '鲁陈路3955弄',
     '立跃路3700弄',
     '立跃路3800弄',
     '北江洲路280弄',
     '浦秀路750弄',
     '浦秀路1139弄',
     '浦秀路765弄',
     '联航路2015弄',
     '联航路2165弄',
     '浦申路633弄',
     '北江燕路228弄',
     '北江燕路338弄',
     '北江燕路68弄',
     '南江燕路69弄',
     '南江燕路229弄',
     '浦驰路345弄',
     '浦申路200弄',
     '浦晓南路59弄',
     '浦驰路188弄',
     '浦驰路235弄',
     '浦秀路499弄',
     '浦秀路518弄',
     '浦秀路598弄',
     '浦秀路639弄',
     '浦瑞路509弄',
     '浦秀路359弄',
     '浦秀路360弄',
     '浦雪路351弄',
     '浦秀路183弄',
     '浦秀路220弄',
     '浦秀路48弄',
     '浦秀路49弄',
     '浦瑞路56弄',
     '浦雪路53弄',
     '浦瑞路55弄',
     '江龙路446弄',
     '浦瑞路369弄',
     '浦鸥路186弄',
     '浦雪路179弄',
     '浦驰南路238弄',
     '江汉路200弄',
     '江汉路99弄',
     '浦驰南路39弄',
     '浦雪南路366弄',
     '浦雪南路369弄',
     '浦雪路206弄',
     '浦瑞南路76弄1—58号',
     '江汉路751弄1—40号',
     '江柳路200弄',
     '江柳路400弄',
     '江桦路199弄',
     '江桦路200弄',
     '江桦路399弄',
     '江桦路400弄',
     '江桦路600弄',
     '浦驰路1659弄',
     '浦锦路1658弄',
     '浦驰路1809弄',
     '江桃路555弄',
     '江桃路556弄',
     '浦秀北路155弄',
     '浦秀北路55弄',
     '浦驰路1628弄',
     '江桃路299弄',
     '江桃路399弄',
     '江栀路500弄',
     '浦驰路1335弄',
     '浦驰路1336弄',
     '江栀路350弄',
     '浦申路1288弄',
     '浦晓路112弄',
     '浦星公路568弄',
     '浦申路2639弄',
     '芦恒路378弄',
     '浦秀路1536弄',
     '浦秀路1535弄',
     '北江桐路536弄',
     '江柳路888弄',
     '江柳路889弄',
     '浦泉路399弄',
     '浦泉路251弄',
     '浦晓路298弄',
     '浦秀路1385弄',
     '浦秀路1386弄',
     '浦锦路1181弄',
     '浦雪路1180弄',
     '浦锦路2159弄',
     '浦锦路2385弄',
     '江松路501弄1-19号',
     '江松路501弄20-43号',
]